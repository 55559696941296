import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {
  ParcelOptionCreateDialogComponent,
  ParcelOptionCreateDialogData,
  ParcelOptionCreateDialogResult,
} from '../components/parcel-option-create-dialog/parcel-option-create-dialog.component';
import {
  ParcelOptionUpdateDialogComponent,
  ParcelOptionUpdateDialogData,
  ParcelOptionUpdateDialogResult,
} from '../components/parcel-option-update-dialog/parcel-option-update-dialog.component';

@Injectable()
export class ParcelOptionDialogService {
  constructor(private dialogs: MatDialog) {}

  openParcelOptionCreateDialog() {
    return this.dialogs.open<
      ParcelOptionCreateDialogComponent,
      ParcelOptionCreateDialogData,
      ParcelOptionCreateDialogResult
    >(ParcelOptionCreateDialogComponent, {
      width: '500px',
    });
  }

  openParcelOptionUpdateDialog(id: number) {
    return this.dialogs.open<
      ParcelOptionUpdateDialogComponent,
      ParcelOptionUpdateDialogData,
      ParcelOptionUpdateDialogResult
    >(ParcelOptionUpdateDialogComponent, {
      width: '500px',
      data: { id },
    });
  }
}
