import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {
  ShippingScheduleDialogComponent,
  ShippingScheduleDialogData,
  ShippingScheduleDialogResult,
} from '../components/shipping-schedule-dialog/shipping-schedule-dialog.component';

@Injectable()
export class ShippingScheduleDialogService {
  constructor(private dialogs: MatDialog) {}

  openShippingScheduleDialog(data: ShippingScheduleDialogData) {
    return this.dialogs.open<
      ShippingScheduleDialogComponent,
      ShippingScheduleDialogData,
      ShippingScheduleDialogResult
    >(ShippingScheduleDialogComponent, {
      data,
      width: '500px',
    });
  }
}
