import { Component } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { ParcelOptionService } from '@x/ecommerce/domain-client';
import { firstValueFrom } from 'rxjs';

export type ParcelOptionCreateDialogData = {};
export type ParcelOptionCreateDialogResult = { id: number };

@Component({
  selector: 'x-parcel-option-create-dialog',
  templateUrl: './parcel-option-create-dialog.component.html',
  styleUrls: ['./parcel-option-create-dialog.component.scss'],
})
export class ParcelOptionCreateDialogComponent {
  formGroup = new UntypedFormGroup({
    name: new UntypedFormControl(null, Validators.required),
  });

  constructor(
    public dialog: MatDialogRef<ParcelOptionCreateDialogComponent>,
    private parcelOptionService: ParcelOptionService,
  ) {}

  async submit() {
    this.formGroup.updateValueAndValidity();
    if (this.formGroup.invalid) return;

    const { id } = await firstValueFrom(this.parcelOptionService.create(this.formGroup.value));
    this.dialog.close({ id });
  }
}
