<h2 mat-dialog-title>{{ verb }} Shipping Slot</h2>
<form
  [formGroup]="formGroup"
  mat-dialog-content
  novalidate
  autocomplete="off"
  (ngSubmit)="submit()"
  id="shipping_slot_form"
>
  <x-form-panel xFormErrorBoundary>
    <div class="row">
      <mat-form-field class="col-6">
        <mat-label>Capacity</mat-label>
        <input matInput type="number" formControlName="capacity" />
      </mat-form-field>
      <mat-form-field class="col-6">
        <mat-label>Priority</mat-label>
        <input matInput type="number" formControlName="priority" min="0" />
      </mat-form-field>
    </div>
    <div class="row">
      <mat-form-field class="col-12">
        <mat-label>Lead Time</mat-label>
        <x-duration-input-control
          formControlName="leadTime"
          [format]="['hr', 'min']"
        ></x-duration-input-control>
        <mat-error xFormError="leadTime"></mat-error>
      </mat-form-field>
    </div>
    <div class="row">
      <mat-form-field class="col-4">
        <mat-label>Slot Start Date</mat-label>
        <!-- <input matInput type="datetime-local" placeholder="Start date" formControlName="startAt" /> -->
        <input matInput type="datetime-local" placeholder="Start date" formControlName="startAt" />
        <mat-hint>({{ timezone }})</mat-hint>
      </mat-form-field>
    </div>
    <div class="row">
      <mat-form-field class="col-4">
        <mat-label>Slot End Date</mat-label>
        <input matInput type="datetime-local" placeholder="End date" formControlName="endAt" />
      </mat-form-field>
    </div>
    <div class="row">
      <mat-form-field class="flex-grow-1" appearance="outline">
        <mat-label>Regions</mat-label>
        <x-data-autocomplete
          formControlName="regionIds"
          [provider]="Providers.GeoRegionItemCollectionProvider"
          [multiple]="true"
        ></x-data-autocomplete>
      </mat-form-field>
    </div>
    <!-- <input matEndDate placeholder="End date" formControlName="endAt" /> -->
  </x-form-panel>
</form>
<div mat-dialog-actions align="end">
  <x-pill color="warn" text="Form contains errors" *ngIf="formGroup.invalid">
    <fa-icon icon="exclamation-circle"></fa-icon>
  </x-pill>
  <button mat-button type="button" (click)="dialog.close()">Cancel</button>
  <button
    mat-button
    type="submit"
    [disabled]="formGroup.invalid"
    color="primary"
    form="shipping_slot_form"
  >
    {{ verb }}
  </button>
</div>
