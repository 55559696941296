import { ChangeDetectionStrategy, Component, Inject, OnDestroy } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ShippingSlotService } from '@x/ecommerce/domain-client';
import { GeoRegionItemCollectionProvider } from '@x/ecommerce/domain-data';
import { DateTime } from 'luxon';
import { Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';

export type ShippingSlotDialogData = {
  shippingSlotId: string;
};
export type ShippingSlotDialogResult = {
  id: number;
};

@Component({
  selector: 'x-shipping-slot-dialog',
  templateUrl: './shipping-slot-dialog.component.html',
  styleUrls: ['./shipping-slot-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ShippingSlotDialogComponent implements OnDestroy {
  Providers = {
    GeoRegionItemCollectionProvider,
  };
  private _destroy$ = new Subject<void>();

  formGroup = new UntypedFormGroup({
    id: new UntypedFormControl(null),
    capacity: new UntypedFormControl(null, Validators.required),
    priority: new UntypedFormControl(null, Validators.required),
    leadTime: new UntypedFormControl(null, Validators.required),
    startAt: new UntypedFormControl(null),
    endAt: new UntypedFormControl(null),
    regionIds: new UntypedFormControl(null),
  });

  shippingSlotId: string;
  methodId: number;
  timezone: string;
  verb: string = 'Update';

  constructor(
    public dialog: MatDialogRef<ShippingSlotDialogComponent>,
    private shippingSlotService: ShippingSlotService,
    @Inject(MAT_DIALOG_DATA) public data: ShippingSlotDialogData,
  ) {
    this.shippingSlotService
      .fetchItem(this.data.shippingSlotId)
      .pipe(
        takeUntil(this._destroy$),
        tap((v) => {
          this.shippingSlotId = v.id;
          this.timezone = v.method.timezone;
          this.formGroup.patchValue(
            {
              ...v,
              // https://github.com/moment/luxon/discussions/1136
              startAt: DateTime.fromISO(v.startAt, { zone: v.method.timezone })
                .startOf('minute')
                .toISO({ includeOffset: false, suppressSeconds: true, suppressMilliseconds: true }),
              endAt: DateTime.fromISO(v.endAt, { zone: v.method.timezone })
                .startOf('minute')
                .toISO({ includeOffset: false, suppressSeconds: true, suppressMilliseconds: true }),
              regionIds: v.regions ? v.regions?.map((r: any) => r.id) : null,
            },
            { emitEvent: false },
          );
          this.formGroup.markAsUntouched();
        }),
      )
      .subscribe();
  }

  submit() {
    this.formGroup.updateValueAndValidity();
    const { value, invalid } = this.formGroup;

    if (invalid) return;
    this.shippingSlotService
      .fetchItem(this.data.shippingSlotId)
      .pipe(
        takeUntil(this._destroy$),
        tap((v) => {
          this.shippingSlotService
            .update({
              ...value,
              startAt: DateTime.fromISO(value.startAt, { zone: v.method.timezone }),
              endAt: DateTime.fromISO(value.endAt, { zone: v.method.timezone }),
            })
            .pipe(
              takeUntil(this._destroy$),
              tap(({ id }) => this.dialog.close({ id })),
            )
            .subscribe();
        }),
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }
}
