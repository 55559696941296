<h2 mat-dialog-title>{{ verb }} Collection Point</h2>
<h3 *ngIf="!create" class="cp">Collection Point: {{ collectionPointName }}</h3>
<form
  mat-dialog-content
  [formGroup]="formGroup"
  id="collectionPoint_form"
  novalidate
  autocomplete="off"
>
  <x-dashboard-form-layout>
    <x-form-panel>
      <mat-form-field class="col-12" appearance="outline">
        <mat-label>Name</mat-label>
        <input matInput type="text" formControlName="name" />
      </mat-form-field>
      <div class="col-12 p-4">
        <mat-slide-toggle formControlName="enabled">Enabled</mat-slide-toggle>
      </div>
      <div>
        <div *ngIf="collectionPointAddress as address">
          <mat-label>Address</mat-label>
          <br />
          <x-address-pill [address]="address" (click)="openAddressFormDialog()"></x-address-pill>
          <button mat-icon-button (click)="openAddressFormDialog()" class="address-btn">
            <fa-icon icon="cog"></fa-icon>
          </button>
        </div>
        <div *ngIf="!collectionPointAddress">
          <span xdHeadline>Address Unassigned</span>
          <button mat-icon-button (click)="openAddressFormDialog()" class="address-btn">
            <fa-icon icon="cog"></fa-icon>
          </button>
        </div>
      </div>
    </x-form-panel>
  </x-dashboard-form-layout>
</form>
<div mat-dialog-actions align="end">
  <button mat-button type="button" mat-dialog-close>Cancel</button>
  <button mat-button type="submit" color="primary" form="collectionPoint_form" (click)="submit()">
    {{ verb }}
  </button>
</div>
