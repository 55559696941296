import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ParcelOptionDetailObject, ParcelOptionService } from '@x/ecommerce/domain-client';
import { BehaviorSubject, firstValueFrom, Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';

export type ParcelOptionUpdateDialogData = { id: number };
export type ParcelOptionUpdateDialogResult = { id: number };

@Component({
  selector: 'x-parcel-option-update-dialog',
  templateUrl: './parcel-option-update-dialog.component.html',
  styleUrls: ['./parcel-option-update-dialog.component.scss'],
})
export class ParcelOptionUpdateDialogComponent implements OnDestroy, OnInit {
  private _destroy$ = new Subject<void>();

  updating$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  formGroup = new UntypedFormGroup({
    id: new UntypedFormControl(this.data.id),
    name: new UntypedFormControl(null, Validators.required),
    dimensions: new UntypedFormControl(null),
    weight: new UntypedFormControl(null),
  });

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: ParcelOptionUpdateDialogData,
    public dialog: MatDialogRef<ParcelOptionUpdateDialogComponent>,
    private parcelOptionService: ParcelOptionService,
  ) {}

  async submit() {
    this.formGroup.updateValueAndValidity();
    if (this.formGroup.invalid) return;
    this.updating$.next(true);
    const { id } = await firstValueFrom(this.parcelOptionService.update(this.formGroup.value));
    this.dialog.close({ id });
  }

  ngOnInit(): void {
    if (this.data && this.data.id) {
      this.parcelOptionService
        .fetchDetail(this.data.id)
        .pipe(
          takeUntil(this._destroy$),
          tap((v: ParcelOptionDetailObject) => {
            this.formGroup.patchValue(v);
          }),
        )
        .subscribe();
    }
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }
}
