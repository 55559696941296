import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {
  CollectionPointDialogComponent,
  CollectionPointDialogData,
  CollectionPointDialogResult,
} from '../components/collection-point-dialog/collection-point-dialog.component';

@Injectable()
export class CollectionPointDialogService {
  constructor(private dialogs: MatDialog) {}

  openCollectionPointCreateDialog(data: { methodId: number; collectionPointId?: number }) {
    return this.dialogs.open<
      CollectionPointDialogComponent,
      CollectionPointDialogData,
      CollectionPointDialogResult
    >(CollectionPointDialogComponent, {
      data,
    });
  }
}
