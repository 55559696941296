import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  OnInit,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AddressDialogService } from '@x/ecommerce-admin/app/core/services/address-dialog.service';
import {
  AddressService,
  CollectionPointService,
  IAddressInput,
  IAddressItem,
} from '@x/ecommerce/domain-client';
import { Subject, takeUntil, tap } from 'rxjs';

export interface CollectionPointDialogData {
  collectionPointId?: number;
  methodId: number;
}

export interface CollectionPointDialogResult {
  name: string;
  enabled: boolean;
  address: IAddressInput;
}

@Component({
  selector: 'x-collection-point-dialog',
  templateUrl: './collection-point-dialog.component.html',
  styleUrls: ['./collection-point-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CollectionPointDialogComponent implements OnInit {
  verb: string = 'Create';
  create: boolean = true;
  collectionPointName: string = '';
  collectionPointAddress: IAddressItem | null = null;

  private _destroy$ = new Subject<void>();

  formGroup = new FormGroup({
    name: new FormControl<string>('', [Validators.required]),
    enabled: new FormControl<boolean>(false),
  });

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: CollectionPointDialogData,
    private dialogRef: MatDialogRef<CollectionPointDialogComponent, CollectionPointDialogResult>,
    private collectionPointService: CollectionPointService,
    private addressDialogService: AddressDialogService,
    private addressService: AddressService,
    private changeRef: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    if (this.data.collectionPointId) {
      this.create = false;
      this.collectionPointService.fetchRow(this.data.collectionPointId).subscribe((cp) => {
        this.formGroup.reset({
          name: cp.name,
          enabled: cp.enabled,
        });
        this.collectionPointName = cp.name;
        this.collectionPointAddress = cp.address;
      });
      this.verb = 'Update';
    }
  }

  submit() {
    this.formGroup.updateValueAndValidity();

    if (this.formGroup.invalid) return;

    const name = this.formGroup.get('name')?.value;
    const enabled = this.formGroup.get('enabled')?.value;

    if (!name || enabled === null || !this.collectionPointAddress) return;

    if (this.data.collectionPointId)
      this.collectionPointService
        .update({
          id: this.data.collectionPointId,
          name,
          enabled,
          address: this.addressService.addressToAddressInput(this.collectionPointAddress),
        })
        .pipe(
          takeUntil(this._destroy$),
          tap((result) => this.dialogRef.close(result)),
        )
        .subscribe();
    else
      this.collectionPointService
        .create({
          name,
          address: this.addressService.addressToAddressInput(this.collectionPointAddress),
          methodId: this.data.methodId,
          enabled,
        })
        .pipe(
          takeUntil(this._destroy$),
          tap((result) => this.dialogRef.close(result)),
        )
        .subscribe();
  }

  async openAddressFormDialog() {
    this.addressDialogService
      .openAddressInputDialog({
        title: 'Edit Collection Point Address',
        value: this.collectionPointAddress,
      })
      .afterClosed()
      .subscribe((result) => {
        if (!result) return;
        this.collectionPointAddress = result.value as IAddressItem;
        this.changeRef.markForCheck();
      });
  }
}
