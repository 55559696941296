import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {
  ShippingMethodDialogComponent,
  ShippingMethodDialogData,
  ShippingMethodDialogResult,
} from '../components/shipping-method-form-dialog/shipping-method-form-dialog.component';

@Injectable()
export class ShippingMethodDialogService {
  constructor(private dialogs: MatDialog) {}

  openShippingMethodDialog(shippingMethodId?: number) {
    return this.dialogs.open<
      ShippingMethodDialogComponent,
      ShippingMethodDialogData,
      ShippingMethodDialogResult
    >(ShippingMethodDialogComponent, { data: { shippingMethodId }, maxWidth: '700px' });
  }
}
