import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ShippingCategoryService } from '@x/ecommerce/domain-client';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

export interface IShippingCategoryFormDialogData {
  id?: number;
}

export interface IShippingCategoryFormDialogResult {
  id?: number;
  name: string;
  description?: string;
}

@Component({
  selector: 'x-shipping-category-form-dialog',
  templateUrl: './shipping-category-form-dialog.component.html',
})
export class ShippingCategoryFormDialogComponent implements OnInit, OnDestroy {
  private _destroy = new Subject<void>();

  formGroup = new UntypedFormGroup({
    name: new UntypedFormControl(null, [Validators.required]),
    description: new UntypedFormControl(null),
  });

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: IShippingCategoryFormDialogData,
    public dialog: MatDialogRef<
      ShippingCategoryFormDialogComponent,
      IShippingCategoryFormDialogResult
    >,
    private shippingCategoryService: ShippingCategoryService,
  ) {}

  ngOnInit(): void {
    if (this.data.id) {
      this.shippingCategoryService
        .fetchItem(this.data.id)
        .pipe(takeUntil(this._destroy))
        .subscribe(({ name, description }) => {
          this.formGroup.patchValue({
            name,
            description,
          });
        });
    }
  }

  ngOnDestroy(): void {
    this._destroy.next();
    this._destroy.complete();
  }

  submit() {
    if (this.formGroup.invalid) {
      return;
    }

    this.dialog.close({
      id: this.data.id,
      name: this.formGroup.get('name')?.value,
      description: this.formGroup.get('description')?.value,
    });
  }
}
