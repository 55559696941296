<h2 mat-dialog-title>Create Parcel Option</h2>
<form [formGroup]="formGroup" novalidate autocomplete="off" (submit)="submit()">
  <x-form-panel>
    <mat-form-field>
      <mat-label>Name</mat-label>
      <input matInput type="text" formControlName="name" />
    </mat-form-field>
  </x-form-panel>
  <div mat-dialog-actions align="end">
    <x-pill color="warn" text="Form contains errors" *ngIf="formGroup.invalid">
      <fa-icon icon="exclamation-circle"></fa-icon>
    </x-pill>
    <button mat-button type="button" (click)="dialog.close()">Cancel</button>
    <button mat-flat-button type="submit" [disabled]="formGroup.invalid" color="primary">
      Create
    </button>
  </div>
</form>
