import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {
  IShippingCategoryFormDialogData,
  IShippingCategoryFormDialogResult,
  ShippingCategoryFormDialogComponent,
} from '../components/shipping-category-form-dialog/shipping-category-form-dialog.component';

@Injectable()
export class ShippingCategoryDialogService {
  constructor(private dialogs: MatDialog) {}

  openShippingCategoryFormDialog(id?: number) {
    return this.dialogs.open<
      ShippingCategoryFormDialogComponent,
      IShippingCategoryFormDialogData,
      IShippingCategoryFormDialogResult
    >(ShippingCategoryFormDialogComponent, { width: '500px', data: { id } });
  }
}
