import { ChangeDetectionStrategy, Component, Inject, OnDestroy } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ShippingMethodService, ShippingScheduleService } from '@x/ecommerce/domain-client';
import { Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';

export type ShippingScheduleDialogData = {
  shippingScheduleId?: number;
  shippingMethodId: number;
};
export type ShippingScheduleDialogResult = {
  id: number;
};

@Component({
  selector: 'x-shipping-schedule-dialog',
  templateUrl: './shipping-schedule-dialog.component.html',
  styleUrls: ['./shipping-schedule-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ShippingScheduleDialogComponent implements OnDestroy {
  private _destroy$ = new Subject<void>();
  enabledControl = new FormControl<boolean>(false, Validators.required);

  formGroup = new FormGroup({
    id: new FormControl<number | null>(null),
    name: new FormControl<string | null>(null, Validators.required),
    interval: new FormControl<string>('0 9 * * 1', [Validators.required]),
    duration: new FormControl<string>('P9H', Validators.required),
    capacity: new FormControl<number | null>(null, Validators.required),
    priority: new FormControl<number>(0, Validators.required),
    leadTime: new FormControl<string | null>(null, Validators.required),
    startAt: new FormControl<string | null>(null),
    endAt: new FormControl<string | null>(null),
    methodId: new FormControl<number>(
      { value: this.data.shippingMethodId, disabled: true },
      Validators.required,
    ),
    enabled: this.enabledControl,
    regionIds: new FormControl<number[]>([]),
  });

  shippingScheduleId: number;
  methodId: number;
  verb: string = 'Create';

  shippingMethod$ = this.shippingMethodService.fetchDetail(this.data.shippingMethodId);

  constructor(
    public dialog: MatDialogRef<ShippingScheduleDialogComponent>,
    private shippingScheduleService: ShippingScheduleService,
    private shippingMethodService: ShippingMethodService,
    @Inject(MAT_DIALOG_DATA) public data: ShippingScheduleDialogData,
  ) {
    if (this.data?.shippingScheduleId) {
      this.shippingScheduleService
        .fetchById(this.data?.shippingScheduleId)
        .pipe(
          takeUntil(this._destroy$),
          tap((v) => {
            this.shippingScheduleId = v.id;
            this.formGroup.patchValue(
              {
                ...v,
              },
              { emitEvent: false },
            );
            this.formGroup.markAsUntouched();
          }),
        )
        .subscribe();
      this.verb = 'Update';
    }

    this.methodId = this.data.shippingMethodId;
  }

  submit() {
    this.formGroup.updateValueAndValidity();
    const { value, invalid } = this.formGroup;

    if (invalid) return;

    value.methodId = this.methodId;
    value.regionIds = value.regionIds && value.regionIds.length ? value.regionIds : null;

    if (this.shippingScheduleId) {
      this.shippingScheduleService
        .update(value as any)
        .pipe(
          takeUntil(this._destroy$),
          tap(({ id }) => this.dialog.close({ id })),
        )
        .subscribe();
    } else {
      const { id, ...create } = value;
      this.shippingScheduleService
        .create(create as any)
        .pipe(
          takeUntil(this._destroy$),
          tap(({ id }) => this.dialog.close({ id })),
        )
        .subscribe();
    }
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }
}
