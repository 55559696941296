<h2 mat-dialog-title>{{ verb }} Shipping Schedule</h2>
<form
  [formGroup]="formGroup"
  mat-dialog-content
  novalidate
  autocomplete="off"
  (ngSubmit)="submit()"
  id="shipping_schedule_form"
  *ngIf="shippingMethod$ | async as shippingMethod"
>
  <x-dashboard-form-layout>
    <x-form-panel xFormErrorBoundary>
      <mat-form-field class="col-12" appearance="outline">
        <mat-label>Name</mat-label>
        <input matInput type="text" formControlName="name" />
        <mat-hint>Schedule Name, eg. Morning</mat-hint>
      </mat-form-field>
      <mat-form-field class="col-12">
        <mat-label>Schedule Interval</mat-label>
        <x-cron-input #intervalInput formControlName="interval"></x-cron-input>
        <x-cron-input-toggle matSuffix [for]="intervalInput"></x-cron-input-toggle>
        <mat-error xFormError="interval"></mat-error>
        <mat-hint>Slot intervals</mat-hint>
      </mat-form-field>
      <mat-form-field class="col-6" floatLabel="always">
        <mat-label>Duration</mat-label>
        <x-duration-input-control
          formControlName="duration"
          [format]="['hr', 'min']"
        ></x-duration-input-control>
        <mat-error xFormError="duration"></mat-error>
      </mat-form-field>
      <mat-form-field class="col-6" floatLabel="always">
        <mat-label>Lead Time</mat-label>
        <x-duration-input-control
          formControlName="leadTime"
          [format]="['hr', 'min']"
        ></x-duration-input-control>
        <mat-error xFormError="leadTime"></mat-error>
        <mat-hint>Lead time required</mat-hint>
      </mat-form-field>
      <mat-form-field class="col-6">
        <mat-label>Capacity</mat-label>
        <input matInput type="number" formControlName="capacity" />
        <mat-hint>Total delivery capacity</mat-hint>
      </mat-form-field>
      <mat-form-field class="col-6">
        <mat-label>Priority</mat-label>
        <input matInput type="number" formControlName="priority" min="0" />
      </mat-form-field>
      <mat-form-field class="col-12">
        <mat-label>Schedule Date Range</mat-label>
        <mat-date-range-input [rangePicker]="picker">
          <input matStartDate placeholder="Start date" formControlName="startAt" />
          <input matEndDate placeholder="End date" formControlName="endAt" />
        </mat-date-range-input>
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-date-range-picker #picker></mat-date-range-picker>
        <mat-hint>Schedule date range</mat-hint>
      </mat-form-field>
      <mat-form-field class="col-12">
        <mat-label>Regions</mat-label>
        <mat-select formControlName="regionIds" multiple>
          <mat-option *ngFor="let region of shippingMethod.regions" [value]="region.id">
            {{ region.name }}
          </mat-option>
        </mat-select>
        <mat-hint>Limit slots to specified regions</mat-hint>
      </mat-form-field>
      <div class="col-12 p-4">
        <mat-slide-toggle formControlName="enabled">Enabled</mat-slide-toggle>
      </div>
    </x-form-panel>
  </x-dashboard-form-layout>
</form>
<div mat-dialog-actions align="end">
  <x-pill color="warn" text="Form contains errors" *ngIf="formGroup.invalid && formGroup.touched">
    <fa-icon icon="exclamation-circle"></fa-icon>
  </x-pill>
  <button mat-button type="button" (click)="dialog.close()">Cancel</button>
  <button
    mat-button
    type="submit"
    [disabled]="formGroup.invalid"
    color="primary"
    form="shipping_schedule_form"
  >
    {{ verb }}
  </button>
</div>
