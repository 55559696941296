<h2 mat-dialog-title>Update</h2>
<form [formGroup]="formGroup" novalidate autocomplete="off" (submit)="submit()">
  <x-form-panel>
    <mat-form-field>
      <mat-label>Name</mat-label>
      <input matInput type="text" formControlName="name" />
    </mat-form-field>
    <mat-form-field>
      <mat-label>Weight</mat-label>
      <input matInput type="number" formControlName="weight" />
      <span matSuffix>g</span>
    </mat-form-field>
    <x-dimension-form-input formControlName="dimensions"></x-dimension-form-input>
  </x-form-panel>
  <div mat-dialog-actions align="end">
    <button mat-button type="button" (click)="dialog.close()">Cancel</button>
    <button mat-button type="submit" [disabled]="formGroup.invalid" color="primary">Update</button>
  </div>
</form>
