<h2 mat-dialog-title>{{ verb }} Shipping Method</h2>
<form
  [formGroup]="formGroup"
  mat-dialog-content
  novalidate
  autocomplete="off"
  (ngSubmit)="submit()"
  id="shipping_method_form"
>
  <x-dashboard-form-layout>
    <x-form-panel xFormErrorBoundary>
      <mat-form-field class="col-6">
        <mat-label>Name</mat-label>
        <input matInput type="text" formControlName="name" />
      </mat-form-field>

      <mat-form-field class="col-6">
        <mat-label>Channel(s)</mat-label>
        <x-data-select
          formControlName="channelIds"
          [multiple]="true"
          [provider]="Providers.ChannelItemCollectionProvider"
        ></x-data-select>
      </mat-form-field>

      <mat-form-field class="col-6">
        <mat-label>Default Warehouse</mat-label>
        <x-data-select
          formControlName="warehouseId"
          [provider]="Providers.WarehouseItemCollectionProvider"
        ></x-data-select>
        <mat-hint>Default fulfilment warehouse</mat-hint>
      </mat-form-field>

      <mat-form-field class="col-6">
        <mat-label>Priority</mat-label>
        <input matInput type="number" formControlName="priority" />
      </mat-form-field>

      <mat-form-field class="col-6">
        <mat-label>Stock Type</mat-label>
        <x-enum-select
          formControlName="stockType"
          enum="StockType"
          [nullable]="false"
        ></x-enum-select>
      </mat-form-field>

      <mat-form-field class="col-6">
        <mat-label>
          <fa-icon icon="layer-plus"></fa-icon>
          Region(s)
        </mat-label>
        <x-data-autocomplete
          formControlName="regionIds"
          [provider]="Providers.GeoRegionItemCollectionProvider"
          [multiple]="true"
        >
          <x-pill *xDataAutocompleteTriggerDef="let option">
            {{ option.item.name }}
          </x-pill>
        </x-data-autocomplete>
      </mat-form-field>

      <mat-slide-toggle class="col-4 p-4" formControlName="slotRequired">
        Slot Required
      </mat-slide-toggle>

      <mat-slide-toggle class="col-4 p-4" formControlName="collectionRequired">
        Collection Required
      </mat-slide-toggle>

      <mat-slide-toggle class="col-4 p-4" formControlName="groupDestination">
        Group Destination
      </mat-slide-toggle>

      <div class="row">
        <mat-expansion-panel class="mat-elevation-z0">
          <mat-expansion-panel-header>
            <mat-panel-title>Display</mat-panel-title>
          </mat-expansion-panel-header>
          <div class="row">
            <mat-form-field class="col-12">
              <mat-label>Display Name</mat-label>
              <input matInput type="text" formControlName="displayName" />
            </mat-form-field>

            <mat-form-field class="col-12">
              <mat-label>Description</mat-label>
              <input matInput type="text" formControlName="description" />
            </mat-form-field>
          </div>
        </mat-expansion-panel>

        <mat-expansion-panel class="mat-elevation-z0">
          <mat-expansion-panel-header>
            <mat-panel-title>Shipping Categories</mat-panel-title>
          </mat-expansion-panel-header>
          <div class="row">
            <mat-form-field class="col-12">
              <mat-label>
                <fa-icon icon="layer-plus"></fa-icon>
                Shipping Categories
              </mat-label>
              <x-data-autocomplete
                formControlName="shippingCategoryIds"
                [provider]="Providers.ShippingCategoryItemCollectionProvider"
                [multiple]="true"
              >
                <x-pill *xDataAutocompleteTriggerDef="let option">
                  {{ option.item.name }}
                </x-pill>
              </x-data-autocomplete>
            </mat-form-field>

            <mat-form-field class="col-12">
              <mat-label>Shipping Category Comparison</mat-label>
              <x-enum-select
                formControlName="categoryComparison"
                enum="ShippingCategoryComparison"
                [nullable]="false"
              ></x-enum-select>
            </mat-form-field>
          </div>
        </mat-expansion-panel>

        <mat-expansion-panel class="mat-elevation-z0">
          <mat-expansion-panel-header>
            <mat-panel-title>Provider</mat-panel-title>
          </mat-expansion-panel-header>
          <div class="row">
            <mat-form-field class="col-12">
              <mat-label>Provider</mat-label>
              <mat-select formControlName="provider">
                <mat-option *ngFor="let item of providers$ | async" [value]="item.id">
                  {{ item.label }}
                </mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field appearance="outline" class="col-12">
              <mat-label>Provider Config</mat-label>
              <textarea
                formControlName="providerConfig"
                matInput
                xInputDense
                cdkTextareaAutosize
                cdkAutosizeMinRows="2"
              ></textarea>
              <mat-hint>JSON format</mat-hint>
              <mat-error xFormError="providerConfig"></mat-error>
            </mat-form-field>
          </div>
        </mat-expansion-panel>

        <mat-expansion-panel class="mat-elevation-z0">
          <mat-expansion-panel-header>
            <mat-panel-title>Delivery Cost</mat-panel-title>
          </mat-expansion-panel-header>
          <div class="row">
            <mat-form-field class="col-12">
              <mat-label>Cost Calculator</mat-label>
              <mat-select formControlName="costCalculator">
                <mat-option *ngFor="let item of costCalculators$ | async" [value]="item.id">
                  {{ item.label }}
                </mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field class="col-6">
              <mat-label>
                <fa-icon icon="warehouse"></fa-icon>
                Cost Product Variant
              </mat-label>
              <x-data-autocomplete
                formControlName="costProductVariantId"
                [provider]="Providers.ProductVariantItemCollectionProvider"
                [multiple]="false"
              ></x-data-autocomplete>
            </mat-form-field>

            <mat-form-field appearance="outline" class="col-12">
              <mat-label>Cost Calculator Config</mat-label>
              <textarea
                formControlName="costCalculatorConfig"
                matInput
                xInputDense
                cdkTextareaAutosize
                cdkAutosizeMinRows="2"
              ></textarea>
              <mat-hint>JSON format</mat-hint>
              <mat-error xFormError="costCalculatorConfig"></mat-error>
            </mat-form-field>
          </div>
        </mat-expansion-panel>

        <mat-expansion-panel class="mat-elevation-z0">
          <mat-expansion-panel-header>
            <mat-panel-title>Label</mat-panel-title>
          </mat-expansion-panel-header>
          <div class="row">
            <mat-form-field class="col-12">
              <mat-label>Label Generator</mat-label>
              <mat-select formControlName="labelGenerator">
                <mat-option *ngFor="let item of labelGenerators$ | async" [value]="item.id">
                  {{ item.label }}
                </mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field appearance="outline" class="col-12">
              <mat-label>Label Generator Config</mat-label>
              <textarea
                formControlName="labelGeneratorConfig"
                matInput
                xInputDense
                cdkTextareaAutosize
                cdkAutosizeMinRows="2"
              ></textarea>
              <mat-hint>JSON format</mat-hint>
              <mat-error xFormError="labelGeneratorConfig"></mat-error>
            </mat-form-field>
          </div>
        </mat-expansion-panel>

        <mat-expansion-panel class="mat-elevation-z0">
          <mat-expansion-panel-header>
            <mat-panel-title>Advanced Options</mat-panel-title>
          </mat-expansion-panel-header>

          <div class="row">
            <mat-form-field class="col-6">
              <mat-label>Quote Validity Duration</mat-label>
              <x-duration-input-control
                formControlName="quoteValidityDuration"
                [format]="['d', 'hr', 'min']"
              ></x-duration-input-control>
            </mat-form-field>

            <mat-form-field class="col-6">
              <mat-label>Timezone</mat-label>
              <mat-select formControlName="timezone">
                <mat-option *ngFor="let timezone of timezones" [value]="timezone">
                  {{ timezone }}
                </mat-option>
              </mat-select>
              <mat-hint>Timezone of delivery slots</mat-hint>
            </mat-form-field>
          </div>
        </mat-expansion-panel>
      </div>
    </x-form-panel>
  </x-dashboard-form-layout>
</form>
<div mat-dialog-actions align="end">
  <x-pill color="warn" text="Form contains errors" *ngIf="formGroup.invalid">
    <fa-icon icon="exclamation-circle"></fa-icon>
  </x-pill>
  <button mat-button type="button" (click)="dialog.close()">Cancel</button>
  <button
    mat-button
    type="submit"
    [disabled]="formGroup.invalid"
    color="primary"
    form="shipping_method_form"
  >
    {{ verb }}
  </button>
</div>
